import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['template', 'container']

  add(e) {
    e.stopImmediatePropagation()
    const tag = this._createNewTag(e)
    this.containerTarget.appendChild(tag)
    this.containerTarget.dispatchEvent(
      new CustomEvent('change', { cancelable: false, bubbles: true })
    )
  }

  remove(e) {
    e.stopImmediatePropagation()
    const container = this.containerTarget.querySelector(
      `[data-id="${e.detail.id}"]`
    )
    if (container) container.remove()
    this.containerTarget.dispatchEvent(
      new CustomEvent('change', { cancelable: false, bubbles: true })
    )
  }

  // Private

  _createNewTag(e) {
    const node = this.templateTarget.content.cloneNode(true)
    const originalTarget = e.originalTarget
    node.firstElementChild.dataset.id = originalTarget.dataset.id
    node.querySelector('[data-target="label"]').textContent =
      originalTarget.dataset.label
    node.querySelector('button').dataset.id = originalTarget.dataset.id
    return node
  }
}
