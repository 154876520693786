import { Controller } from 'stimulus'
import { csrfToken } from '@rails/ujs'

export default class extends Controller {
  static values = {
    turboStream: Boolean,
    url: String,
    method: String,
    form: Boolean,
  }

  request(e) {
    fetch(this.urlValue, {
      method: this.methodValue,
      body: new FormData(this.element),
      headers: { 'X-CSRF-Token': csrfToken() },
    })
      .then((response) => response.text())
      .then((html) => {
        document.body.insertAdjacentHTML('beforeend', html)
      })
  }
}
