import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['countryOption', 'regionOptions']

  connect() {
    this.toggle()
  }

  toggle() {
    let anyEnabled = false
    this.countryOptionTargets.forEach(function (element) {
      if (!element.disabled) anyEnabled = true
    })

    if (anyEnabled) {
      this.regionOptionsTarget.classList.remove('hidden')
    } else {
      this.regionOptionsTarget.classList.add('hidden')
    }
  }
}
