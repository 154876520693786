import { Controller } from 'stimulus'

// GA4 eCommerce Controller
//
// Provides a simple wrapper for adding ecommerce data payloads to a view and having them passed off as google tags
export default class extends Controller {
  static values = {
    purchasePayload: Object,
  }

  connect() {
    if (window.dataLayer) this.enabled = true
  }

  purchase() {
    this.sendEvent('purchase', this.purchasePayloadValue)
  }

  sendEvent(eventName, payload) {
    if (!this.enabled) return false

    gtag('event', eventName, payload)
  }
}
