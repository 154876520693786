import { Controller } from 'stimulus'
import setReadonlyProperty from "../lib/misc";

export default class extends Controller {
  static targets = ['listContainer', 'tagContainer']
  add(event) {
    this._delegateEvent(event, this.tagContainerTarget)
  }

  remove(event) {
    this._delegateEvent(event, this.tagContainerTarget)
  }

  deselect(event) {
    this._delegateEvent(event, this.listContainerTarget)
  }

  _delegateEvent(e, target) {
    const newEvent = new e.constructor(e.type, e)
    setReadonlyProperty(newEvent, 'originalTarget', e.target)

    target.dispatchEvent(newEvent)
  }
}
