import { Controller } from 'stimulus'

export default class extends Controller {
  remove() {
    const event = new CustomEvent('tag:removed', {
      cancelable: false,
      bubbles: true,
      detail: { id: this.element.dataset.id },
    })
    this.element.dispatchEvent(event)
    this.element.remove()
    this.element.dispatchEvent(
      new CustomEvent('change', { cancelable: false, bubbles: true })
    )
  }
}
