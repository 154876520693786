import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    optionClass: String,
    selectedOptionClass: String,
    labelClass: String,
    selectedLabelClass: String,
    iconClass: String,
    selectedIconClass: String,
  }

  static targets = ['field', 'label', 'icon']

  connect() {
    this.initializing = true
    this.isSelected = 'selected' in this.element.dataset
    this.initializing = false
    const _hasMutatedCallback = this._hasMutated.bind(this)
    const observer = new MutationObserver(_hasMutatedCallback)
    observer.observe(this.element, {
      attributes: true,
      attributeFilter: ['data-selected'],
    })
  }

  deselect(e) {
    this.isSelected = false
  }

  toggle() {
    this.isSelected = !this.isSelected
  }

  _hasMutated(mutations) {
    for (const mutation of mutations) {
      if (mutation.type === 'attributes') {
        const eventName = this.isSelected ? 'selected' : 'deselected'
        const event = new CustomEvent(`option:${eventName}`, {
          cancelable: false,
          bubbles: true,
          detail: { id: this.element.dataset.id },
        })
        this.element.dispatchEvent(event)

        // Trigger a toggled event for the field itself
        const optionEvent = new CustomEvent('option:toggled', {
          cancelable: false,
          bubbles: false,
          detail: { id: this.element.dataset.id },
        })
        this.fieldTarget.dispatchEvent(optionEvent)
      }
    }
  }

  get isSelected() {
    return 'selected' in this.element.dataset
  }

  set isSelected(value) {
    this.fieldTarget.disabled = !value
    if (value) {
      this.element.dataset.selected = true

      this.element.classList.add(...this.selectedOptionClassValue.split(' '))
      this.labelTarget.classList.add(...this.selectedLabelClassValue.split(' '))
      this.iconTarget.classList.add(...this.selectedIconClassValue.split(' '))

      this.element.classList.remove(...this.optionClassValue.split(' '))
      this.labelTarget.classList.remove(...this.labelClassValue.split(' '))
      this.iconTarget.classList.remove(...this.iconClassValue.split(' '))
    } else {
      delete this.element.dataset.selected
      this.element.classList.remove(...this.selectedOptionClassValue.split(' '))
      this.labelTarget.classList.remove(
        ...this.selectedLabelClassValue.split(' ')
      )
      this.iconTarget.classList.remove(
        ...this.selectedIconClassValue.split(' ')
      )

      this.element.classList.add(...this.optionClassValue.split(' '))
      this.labelTarget.classList.add(...this.labelClassValue.split(' '))
      this.iconTarget.classList.add(...this.iconClassValue.split(' '))
    }
  }
}
