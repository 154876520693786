import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    delay: Number,
    repeating: Boolean,
  }

  connect() {
    this.timer = (this.repeatingValue ? setInterval : setTimeout)(() => {
      const event = new Event(this.eventName, {
        cancelable: false,
        bubbles: true,
      })
      this.element.dispatchEvent(event)
    }, this.delayValue)
  }

  disconnect() {
    ;(this.repeatingValue ? clearInterval : clearTimeout)(this.timer)
  }

  get eventName() {
    return this.repeatingValue ? 'timer:tick' : 'timer:done'
  }
}
