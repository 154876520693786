/* global paypal */

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button', 'paymentSource', 'paypalOrderId']

  connect() {
    const paymentSourceTarget = this.paymentSourceTarget
    const paypalOrderIdTarget = this.paypalOrderIdTarget
    const form = this.element
    this._destroyHandler = this._destroy.bind(this)
    document.addEventListener('turbolinks:before-visit', this._destroyHandler)
    this.button = paypal.Buttons({
      style: {
        size: 'responsive',
        height: 38,
        shape: 'rect',
        color: 'white',
        layout: 'horizontal',
        label: 'pay',
        fundingicons: 'false',
      },

      createOrder: function (data, actions) {
        paymentSourceTarget.value = 'paypal'
        return actions.order.create({
          purchase_units: [
            {
              description: 'Premium Press Release Distribution',
              amount: { currency_code: 'GBP', value: 24 },
            },
          ],
        })
      },

      onApprove: function (data, actions) {
        console.log(data)
        return actions.order.capture().then((details) => {
          paypalOrderIdTarget.value = details.id
          form.submit()
        })
      },

      onError: function (err) {
        console.log(err)
      },
    })
    this.button.render(this.buttonTarget)
  }

  _destroy() {
    this.button.instances.forEach((instance) => instance.close())
    this.buttonTarget.innerHTML = ''
    document.removeEventListener(
      'turbolinks:before-cache',
      this._destroyHandler
    )
  }
}
