// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus'
import ExistenceController from 'stimulus-existence'
import DataBindingController from 'stimulus-data-bindings'
import RevealController from 'stimulus-reveal'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

const application = Application.start()
application.register('existence', ExistenceController)
application.register('data-binding', DataBindingController)
application.register('reveal', RevealController)
const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))
