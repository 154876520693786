import { Controller } from 'stimulus'
import { csrfToken, enableElement } from '@rails/ujs'
/* global Stripe */

export default class extends Controller {
  static targets = [
    'card',
    'cardErrors',
    'submit',
    'paymentSource',
    'paymentMethodId',
    'paymentIntentId',
    'addressLine1',
    'addressLine2',
    'addressLine3',
    'addressLine4',
    'city',
    'region',
    'postcode',
    'country',
    'cardholderName',
  ]

  static values = {
    paymentUrl: String,
    paymentConfirmed: Boolean,
    clientSecret: String,
    billingEmail: String,
  }

  connect() {
    this.stripe = Stripe(this.publishableKey)
    this.elements = this.stripe.elements()
    if (this.hasCardTarget) this._createCardElement()
  }

  createPaymentMethod(event) {
    if (this.paymentConfirmedValue) {
      this._paymentSucceeded()
      return true
    } else {
      event.preventDefault()
      this._paymentFailed()
    }

    const stripeDidReceivePaymentMethodResponse = this._stripeDidReceivePaymentMethodResponse.bind(
      this
    )
    this.stripe
      .createPaymentMethod({
        type: 'card',
        card: this.cardElement,
        billing_details: this.billingDetails,
      })
      .then(stripeDidReceivePaymentMethodResponse)
  }

  _createCardElement() {
    this.cardElement = this.elements.create('card', {
      classes: {
        base: this.cardTarget.dataset.baseClass,
        complete: this.cardTarget.dataset.completeClass,
        empty: this.cardTarget.dataset.emptyClass,
        focus: this.cardTarget.dataset.focusClass,
        invalid: this.cardTarget.dataset.invalidClass,
      },
      hidePostalCode: true,
    })
    this.cardElement.on('change', (event) => {
      if (event.error) {
        this.cardErrorsTarget.textContent = event.error.message
      } else {
        this.cardErrorsTarget.textContent = ''
      }
    })
    this.cardElement.mount(this.cardTarget)
  }

  _stripeDidReceivePaymentMethodResponse(result) {
    if (result.error) {
      // show error in payment form
      this.cardErrorsTarget.textContent = result.error.message
    } else {
      // otherwise send paymentmethod.id to server (along with everything else)
      this.cardErrorsTarget.textContent = ''
      this.paymentMethodIdTarget.value = result.paymentMethod.id
      fetch(this.paymentUrlValue, {
        method: 'PUT',
        headers: {
          'X-CSRF-Token': csrfToken(),
        },
        body: new FormData(this.element),
      }).then((response) => {
        const contentType = response.headers.get('content-type')
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.json().then((json) => {
            this.paymentIntentIdTarget.value =
              json.payment.stripe_payment_intent_id
            this.paymentConfirmedValue = json.payment.succeeded

            if (json.payment.requires_action) {
              this._handleCardAction(json.payment)
            } else {
              this._submitForm()
            }
          })
        } else {
          return response.text().then((html) => {
            document.body.insertAdjacentHTML('beforeend', html)
          })
        }
      })
    }
  }

  _handleCardAction(payment) {
    this.stripe
      .handleCardAction(payment.stripe_client_secret)
      .then((result) => {
        if (result.error) {
          this.cardErrorsTarget.textContent = result.error.message
          enableElement(this.element)
        } else {
          this.cardErrorsTarget.textContent = ''
          this._submitForm()
        }
      })
  }

  _submitForm() {
    this.element.requestSubmit()
  }

  _paymentSucceeded() {
    this.element.dispatchEvent(new CustomEvent('stripe:succeeded'))
  }

  _paymentFailed() {
    this.element.dispatchEvent(new CustomEvent('stripe:failed'))
  }

  get publishableKey() {
    return document.querySelector('meta[name="stripe_publishable_key"]').content
  }

  get billingDetails() {
    return {
      name: this.cardholderNameTarget.value,
      email: this.billingEmailValue,
      address: {
        city: this.cityTarget.value,
        country: this.countryTarget.value,
        line1: this.addressLine1Target.value,
        line2: this.addressLine2Target.value,
        postal_code: this.postcodeTarget.value,
        state: this.regionTarget.value,
      },
    }
  }
}
