import { Controller } from 'stimulus'

export default class extends Controller {
  deselect(e) {
    if (e) e.stopImmediatePropagation()
    const element = this.element.querySelector(`[data-id="${e.detail.id}"]`)
    element.dispatchEvent(
      new Event('deselect', { bubbles: false, cancelable: false })
    )
  }
}
