import { Controller } from 'stimulus'
import debounce from 'lodash.debounce'

export default class extends Controller {
  static values = {
    url: String,
    debounce: Number,
    param: String,
  }

  static targets = [
    'input',
    'field',
    'results',
    'searchIcon',
    'clearIcon',
    'errorIcon',
  ]

  connect() {
    this.search = debounce(this.search, this.debounceValue)
  }

  search(e) {
    const searchParams = new URLSearchParams()
    searchParams.append(this.paramValue, e.target.value)
    fetch(`${this.urlValue}?${searchParams.toString()}`)
      .then((response) => response.text())
      .then((data) => {
        const template = document.createElement('template')
        template.innerHTML = data
        this._renderResults(template)
      })
  }

  select(e) {
    this.hide()
    this.inputTarget.value = e.currentTarget.dataset.value
    this.fieldTarget.value = e.currentTarget.dataset.id
    this.inputTarget.disabled = true
    this.searchIconTarget.hidden = true
    if (this.hasErrorIconTarget) this.errorIconTarget.hidden = true
    this.clearIconTarget.hidden = false
  }

  clear(e) {
    this.inputTarget.value = ''
    this.fieldTarget.value = ''
    this.inputTarget.disabled = false
    if (!this.hasErrorIconTarget) this.searchIconTarget.hidden = false
    if (this.hasErrorIconTarget) this.errorIconTarget.hidden = false
    this.clearIconTarget.hidden = true
    this.inputTarget.focus()
  }

  show() {
    const event = new Event('autocomplete:show', {
      cancelable: false,
      bubbles: true,
    })
    this.resultsTarget.dispatchEvent(event)
  }

  hide() {
    const event = new Event('autocomplete:hide', {
      cancelable: false,
      bubbles: true,
    })
    this.resultsTarget.dispatchEvent(event)
  }

  // private

  _renderResults(template) {
    this.resultsTarget.innerHTML = ''
    this.resultsTarget.appendChild(template.content)
    if (this.fieldTarget.value !== '') {
      const selectedOption = this.resultsTarget.querySelector(
        `[data-id='${this.fieldTarget.value}']`
      )
      if (selectedOption) selectedOption.dataset.selected = true
    }
    this.show()
  }
}
