import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import { csrfToken } from '@rails/ujs'

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      onEnd: this.end.bind(this),
    })
  }

  end(event) {
    const id = event.item.dataset.id
    const url = this.data.get('url').replace(':id', id)
    const data = new FormData()
    data.append('syndication_option[position]', event.newIndex + 1)

    fetch(url, {
      method: 'PUT',
      body: data,
      headers: { 'X-CSRF-Token': csrfToken() },
    })
  }
}
