import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['countryOption', 'languageOptions']

  connect() {
    this.toggle()
  }

  toggle() {
    let anyEnabled = false
    const enabledLanguages = []

    this.countryOptionTargets.forEach(function (element) {
      if (!element.disabled) {
        anyEnabled = true
        enabledLanguages.push(element.dataset.languagesReference)
      }
    })

    anyEnabled ? this.showTranslations() : this.hideTranslations()

    this._toggleEnabledLanguages(enabledLanguages)
  }

  hideTranslations() {
    this.languageOptionsTarget.classList.add('hidden')
  }

  showTranslations() {
    this.languageOptionsTarget.classList.remove('hidden')
  }

  _toggleEnabledLanguages(enabledLanguages) {
    const languageFields = document.querySelectorAll('[data-languages-countries]')

    languageFields.forEach(function (field) {
      field.classList.add('hidden')

      enabledLanguages.forEach(function (country) {
        if (field.dataset.languagesCountries.includes(country)) {
          field.classList.remove('hidden')
        }
      })
    })
  }
}
