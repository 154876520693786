import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    optionName: String
  }

  optionToggle() {
    var allLi = document.querySelectorAll('.rewrite-option');
    var li = event.target.closest('li');

    allLi.forEach(li => {
      this.removeSelectedClasses(li);
    })

    this.toggleSelectedClasses(li);
  }

  toggleSelectedClasses(element) {
    element.classList.toggle('border-electric-violet-500')
    element.classList.toggle('bg-electric-violet-50')
    element.classList.toggle('ring')
    element.classList.toggle('ring-offset-1')
    element.classList.toggle('ring-electric-violet-500')
  }

  removeSelectedClasses(element) {
    element.classList.remove('border-electric-violet-500')
    element.classList.remove('bg-electric-violet-50')
    element.classList.remove('ring')
    element.classList.remove('ring-offset-1')
    element.classList.remove('ring-electric-violet-500')
  }
}
