import { Controller } from 'stimulus'
import { csrfToken } from '@rails/ujs'

export default class extends Controller {
    static values = {
        url: String,
        method: String,
        pressReleaseId: String
    }

    apply() {
        fetch(this.urlValue, {
            method: this.methodValue,
            body: new FormData(this.element),
            headers: {
                'X-CSRF-Token': csrfToken()
            },
        }).then((response) => response.text()).then((html) => {
            document.body.insertAdjacentHTML('beforeend', html)
        })
    }

    remove() {
        if(confirm('Are you sure you want to remove your promo code?')) {
            var formData = new FormData;
            formData.append('press_release[id]', this.pressReleaseIdValue);

            fetch(this.urlValue, {
                method: this.methodValue,
                body: formData,
                headers: {
                    'X-CSRF-Token': csrfToken()
                }
            }).then((response) => response.text()).then((html) => {
                document.body.insertAdjacentHTML('beforeend', html)
            })
        } else {
            return;
        }
    }
}
